<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-12">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Pontos de Vendas</h3>
        </div>
        <div class="card-body table-responsive">
          <b-table
            :fields="[
              'id',
              'logo',
              'nome',
              'endereco',
              'numero',
              'nome_cidade',
              'status',
              'acoes',
            ]"
            :items="lista_pdvs"
            :per-page="perPage"
            :current-page="currentPage"
            id="perfil-table"
            class="table table-head-custom  table-vertical-center table-head-bg table-borderless"
            show-empty
            empty-text="Nenhum registro encontrado!"
          >
            <template #cell(logo)="{ item }">
              <v-img max-height="38" max-width="38" :src="item.logo ?item.logo :'media/logos/bt.png'"></v-img>
            </template>

            <template #head(acoes)><span></span></template>
            <template #cell(status)="{ item }">
              <span
                class="label label-lg label-inline px-2 pb-2 pt-3"
                :class="{
                  'label-light-success': item.status === 1,
                  'label-light-danger': item.status === 2,
                }"
              >
                {{ item.status === 1 ? "Ativo" : "Inativo" }}
              </span></template
            >

            <template #cell(acoes)="{ item }">
              <div class="text-right w-100">
                <button
                  v-show="lista_permissoes_filial.u_Pdv"
                  @click="atualizar(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Editar registro"
                >
                  <i class="far fa-edit text-primary"></i>
                </button>

                <button
                  v-show="lista_permissoes_filial.lock_Pdv"
                  @click="confirm(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Bloquear/Desbloquear registro"
                >
                  <i class="fas fa-trash text-danger"></i>
                </button>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="lista_pdvs.length"
            :per-page="perPage"
            aria-controls="perfil-table"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],

  data() {
    return {
      currentPage: 1,
      perPage: 10,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Pdv" }]);
  },

  created() {
    this.listar_pdvs();
  },
  computed: {
    lista_pdvs() {
      return this.$store.state.pdv.lista_pdvs;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.pdv.mensagem_alert;
    },
  },
  methods: {
    async listar_pdvs() {
      await this.$store.dispatch("pdv/listar_pdvs");

      this.$store.dispatch("configEmpresa/atualizar", "");
    },
    atualizar(value) {
      this.$router.push({ name: "createPdv" });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloqueara este PDV no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("pdv/bloquear_pdv", value);
      this.fireAlert({
        ...this.mensagem_alert,
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>