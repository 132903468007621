var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center align-items-md-center"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card full-height bg-ligth"},[_vm._m(0),_c('div',{staticClass:"card-body table-responsive"},[_c('b-table',{staticClass:"table table-head-custom table-vertical-center table-head-bg table-borderless",attrs:{"fields":[
            'id',
            'logo',
            'nome',
            'endereco',
            'numero',
            'nome_cidade',
            'status',
            'acoes',
          ],"items":_vm.lista_pdvs,"per-page":_vm.perPage,"current-page":_vm.currentPage,"id":"perfil-table","show-empty":"","empty-text":"Nenhum registro encontrado!"},scopedSlots:_vm._u([{key:"cell(logo)",fn:function({ item }){return [_c('v-img',{attrs:{"max-height":"38","max-width":"38","src":item.logo ?item.logo :'media/logos/bt.png'}})]}},{key:"head(acoes)",fn:function(){return [_c('span')]},proxy:true},{key:"cell(status)",fn:function({ item }){return [_c('span',{staticClass:"label label-lg label-inline px-2 pb-2 pt-3",class:{
                'label-light-success': item.status === 1,
                'label-light-danger': item.status === 2,
              }},[_vm._v(" "+_vm._s(item.status === 1 ? "Ativo" : "Inativo")+" ")])]}},{key:"cell(acoes)",fn:function({ item }){return [_c('div',{staticClass:"text-right w-100"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial.u_Pdv),expression:"lista_permissoes_filial.u_Pdv"},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-icon btn-light btn-sm mx-1",attrs:{"title":"Editar registro"},on:{"click":function($event){return _vm.atualizar(item)}}},[_c('i',{staticClass:"far fa-edit text-primary"})]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial.lock_Pdv),expression:"lista_permissoes_filial.lock_Pdv"},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-icon btn-light btn-sm mx-1",attrs:{"title":"Bloquear/Desbloquear registro"},on:{"click":function($event){return _vm.confirm(item)}}},[_c('i',{staticClass:"fas fa-trash text-danger"})])])]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.lista_pdvs.length,"per-page":_vm.perPage,"aria-controls":"perfil-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Pontos de Vendas")])])
}]

export { render, staticRenderFns }